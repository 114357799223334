import type { JSX } from 'react';

import { Redirect } from '@change-corgi/core/react/router';
import { Container } from '@change-corgi/design-system/layout';
import { Heading } from '@change-corgi/design-system/typography';

import type { PrefetchedDataProps } from 'src/shared/prefetch';
import { withPrefetchedData } from 'src/shared/prefetch';

import { getMatchingRoute } from 'src/app/pages/petition/shared/routes';
import type { UserInfoState } from 'src/app/pages/petition/shared/types';
import { isError, isLoading } from 'src/app/shared/utils/async';

import { useCurrentRoute } from '../hooks/currentRoute';
import { prefetchPetitionInfo, usePetitionInfo } from '../hooks/petitionInfo';
import type { PetitionInfoState } from '../hooks/petitionInfo';

import { BannerContainer } from './BannerContainer';
import { NavigationContainer } from './NavigationContainer';

type Props = Readonly<{
	slug: string;
	userInfoState: UserInfoState;
}>;
type PrefetchedData = { info: PetitionInfoState };

export const PetitionHeaderContainer = withPrefetchedData(
	({ slug, prefetchedData, userInfoState }: Props & PrefetchedDataProps<PrefetchedData>): JSX.Element | null => {
		const petitionInfoState = usePetitionInfo(slug, prefetchedData?.info);

		const route = useCurrentRoute();

		// TODO use a fixed height or a ghost to avoid layout shifting?
		if (isLoading(petitionInfoState) || isLoading(userInfoState)) return null;

		if (isError(petitionInfoState) && petitionInfoState.reason === 'notFound') {
			return <Redirect to="/?petition_not_found=true" />;
		}

		if (isError(petitionInfoState)) {
			throw new Error('Petition Header: Failed to load petition details', { cause: petitionInfoState.cause });
		}
		if (isError(userInfoState)) {
			throw new Error('Petition Header: Failed to load user info', { cause: userInfoState.cause });
		}

		const { ask: title } = petitionInfoState.info;
		const { info: userInfo } = userInfoState;

		const isOwner = userInfoState.info.ownPetition;

		return (
			<>
				{route?.showBanner && (
					<BannerContainer
						petitionId={petitionInfoState.info.id}
						emergencyBanner={petitionInfoState.info.emergencyBanner}
						signed={userInfo.signed}
					/>
				)}
				{!isOwner && (
					<>
						{!route?.hideTitle && (
							<Container variant="small" px={8}>
								<Heading as="h1" size={['h4', 'h3']} mt={16}>
									{title}
								</Heading>
							</Container>
						)}
						<NavigationContainer
							slug={slug}
							activeTab={route?.tab}
							petitionInfo={petitionInfoState.info}
							userInfo={userInfo}
						/>
					</>
				)}
			</>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: ({ path }) => {
			const route = getMatchingRoute(path);
			return route?.showBanner ? [BannerContainer] : [];
		},
		prefetchData: async (context) => {
			const {
				params: { slug },
				utilityContext,
			} = context;
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return { info: await prefetchPetitionInfo(slug!, utilityContext) };
		},
	},
);
